import { Link } from "react-router-dom"

const AuthView = props => {
	return (
		<>
			<h2>Auth View</h2>
            <Link to="#" to="/main">Main View</Link>
		</>
	);
};

export default AuthView;
